import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import logowhite from '../img/logowhitev1.svg'
import Social from '../components/Social'
import { TrimStr } from '../components/Trim'
import Img from "gatsby-image"

/* eslint-disable */
const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  componentDidMount(){
    // Create a media condition that targets viewports at least 1208px wide
    const mediaQuery = window.matchMedia('(max-width: 1023px)');
    mediaQuery.addListener(handleTabletChange);
    handleTabletChange(mediaQuery);

    // Register event listener
    //mediaQuery.addListener(handleTabletChange);
    //handleTabletChange(mediaQuery);
  }



  render() {
    const { data } = this.props
    const { edges: offers } = data.offers
    const { edges: suboffers } = data.suboffers
    const { edges: portfolios } = data.portfolios

    let cats = []

    portfolios.forEach(edge => {
      if(!cats.includes(edge.node.frontmatter.industry)){
      cats.push(edge.node.frontmatter.industry)
      }
    })
    //console.log(suboffers)
    return (




      <nav
        className="navbar "
        role="navigation"
        aria-label="main-navigation"
        style={{zIndex: '10000',minHeight:'auto',paddingBottom:'10px'}}
      >

      <div>
          <Link aria-label="kontakt z e-multicontent" to="/contact-us/"> <div className="uploader"></div></Link>
          <a aria-label="zadzwoń do nas" href="tel:+48713074517"> <div className="phoner"> </div></a>

      </div>
        <div className="container" style={{  position:'fixed',top:'0px' }}>
          <div className="navbar-brand" >

            <Link to="/" className="navbar-item" id="main-nav" title="Logo" >
              <img src={logowhite} alt="e-multicontent - copywriting, translations, seo, websites, e-commerce, marketplace" style={{ width: '150px',transform:'scale(1.4)', zIndex: '5000', position: 'fixed', top: '5px', left:'0.5%',  }} />
            </Link>


            {/* Hamburger menu */}
            <button
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
              onKeyDown={() => this.toggleHamburger()}
              aria-label="main-navigation"
              style={{ backgroundColor: 'rgb(0,0,0)',borderWidth: '0px', color:'rgb(255,255,255)',zIndex:'1000000',position:'fixed',right:'0px',top:'-2px'}}
            >
              <span />
              <span />
              <span />

            </button>
          </div>
          <div style={{position:'absolute',left: '2px',top:'60px',zIndex:'5000',width:'30px'}}>
            <Social />
          </div>


          <button
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
            style={{border:'0px solid black',fontSize:'1em',zIndex:'1'}}
          >
            <div style={{zIndex: '1000000'}} className="navbar-start has-text-centered">


              <Link className="navbar-item" to="/">
                Home
              </Link>
              <Link className="navbar-item" to="/about-us/">
                About us
              </Link>

              <span id="off" className="navbar-item">
              <Link style={{color:'white'}} to="/offer/">
                Offer </Link> <span id="subtog" style={{marginLeft:'10px',cursor:'pointer'}}><img id="down" src="/img/angle-down.svg" alt="arrow" width="15px" height="10px" /> </span>
              </span>





              <nav className="subMenu" id="sub">


              {offers &&
                offers
                .map(({ node: offer }) => (

                  <div className="column is-4">
                  <span className="navbar-item has-sub">
                  { offer.frontmatter.title === 'E-commerce package subscriptions' ?
                  (
                    <Link key={offer.id} style={{border:'1px solid #36b3d2',borderRadius:'5px'}} className='navbar-item' to={offer.fields.slug} >
                      {offer.frontmatter.title}
                    </Link>
                  ):
                  <Link key={offer.id} className='navbar-item' to={offer.fields.slug} >
                    {offer.frontmatter.title}
                  </Link>
                  }

                  {suboffers &&
                      suboffers
                      .filter(post => post.node.frontmatter.category === offer.frontmatter.title).length !== 0 ? <span className="toggle" style={{marginLeft:'10px',cursor:'pointer'}}><img className="dicon" src="/img/angle-down.svg" alt="arrow" width="15px" height="10px" /> </span> : <span style={{display:'none'}} className="dicon toggle"></span> }
                   </span>
                   <div className="subsub">
                     {suboffers &&
                       suboffers
                       .filter(post => post.node.frontmatter.category === offer.frontmatter.title)
                       .map(({ node: post }) => (
                         <Link key={post.id} className='navbar-item' to={post.fields.slug} >
                           {post.frontmatter.title}
                         </Link>
                     ))}
                   </div>
                   </div>

                  ))}




              </nav>








              <Link className="navbar-item" to="/blog/">
                Blog
              </Link>
              <Link className="navbar-item" to="/contact-us/">
                Contact us
              </Link>
              <span id="port" className="navbar-item">
              <Link style={{color:'white'}} to="/portfolio/">
                Portfolio </Link> <span id="subtog2" style={{marginLeft:'10px',cursor:'pointer'}}><img id="down2" src="/img/angle-down.svg" alt="arrow" width="15px" height="10px" /> </span>
              </span>
              <nav className="subMenu" id="sub2">
              {cats.sort().reverse().map(cat => {
                return (
                  <>
                  <Link key={TrimStr(cat)} className='navbar-item' style={{color:'white'}} to={'/portfolio/'+TrimStr(cat)+'/'} >
                    {cat}
                  </Link>
                  </>
                )
              })}
              </nav>
              <Link className="navbar-item"  to="/career/">
                Career
              </Link>
              <Link className="navbar-item"  to="/partners/">
                Partners
              </Link>
              {/*<Link className="navbar-item"  to="/akademia-beomni-e-commerce/">
                Akademia BEOMNI
              </Link>*/}


            </div>
            <div className="navbar-end has-text-centered">


              <a
                id="lang-switch"
                className="navbar-item"
                href="https://e-multicontent.pl"
                rel="noopener noreferrer"
              >
              <Img fixed={data.file.childImageSharp.fixed} alt="polish" aria-label="polish" />
              &nbsp;&nbsp; PL</a>


            </div>
          </button>
        </div>
      </nav>
    )
  }
}




function handleTabletChange(e) {
  // Check if the media query is true
  if (e.matches) {

    document.getElementById('off').removeEventListener("mouseover",loadSubMenu);
    document.getElementById('off').removeEventListener("mouseleave",hideSubMenu);
    document.getElementById('off').removeEventListener("focus",hideSubMenu);

    document.getElementById('sub').removeEventListener("mouseleave",hideSubMenu);
    document.getElementById('sub').removeEventListener("mouseover",loadSubMenu);

    document.getElementById('navMenu').removeEventListener("mouseleave",hideSubMenu);
    document.getElementById('subtog').removeEventListener("mouseover",loadSubMenu);
    document.getElementById('subtog').addEventListener("click",showSubMenu);

    document.getElementById('port').removeEventListener("mouseover",loadSubMenu2);
    document.getElementById('port').removeEventListener("mouseleave",hideSubMenu2);
    document.getElementById('port').removeEventListener("focus",hideSubMenu2);

    document.getElementById('sub2').removeEventListener("mouseleave",hideSubMenu2);
    document.getElementById('sub2').removeEventListener("mouseover",loadSubMenu2);

    document.getElementById('navMenu').removeEventListener("mouseleave",hideSubMenu2);
    document.getElementById('subtog2').removeEventListener("mouseover",loadSubMenu2);
    document.getElementById('subtog2').addEventListener("click",showSubMenu2);

    for (let i=0;i<document.getElementsByClassName('subsub').length;i++){
      document.getElementsByClassName('subsub')[i].classList.remove('subadd');
    }

    for (let i=0;i<document.getElementsByClassName('toggle').length;i++){
      document.getElementsByClassName('toggle')[i].addEventListener("click", function fn(){showSubSub(i)});
    }




  } else {


  document.getElementById('off').addEventListener("mouseover",loadSubMenu);
  document.getElementById('off').addEventListener("mouseleave",hideSubMenu);
  document.getElementById('off').addEventListener("focus",hideSubMenu);

  document.getElementById('port').addEventListener("mouseover",loadSubMenu2);
  document.getElementById('port').addEventListener("mouseleave",hideSubMenu2);
  document.getElementById('port').addEventListener("focus",hideSubMenu2);


  document.getElementById('sub').addEventListener("mouseleave",hideSubMenu);
  document.getElementById('sub').addEventListener("mouseover",loadSubMenu);
  document.getElementById('subtog').addEventListener("mouseover",loadSubMenu);

  document.getElementById('sub2').addEventListener("mouseleave",hideSubMenu2);
  document.getElementById('sub2').addEventListener("mouseover",loadSubMenu2);
  document.getElementById('subtog2').addEventListener("mouseover",loadSubMenu2);

  document.getElementById('navMenu').addEventListener("mouseleave",hideSubMenu);
  document.getElementById('subtog').removeEventListener("click",showSubMenu);

  document.getElementById('navMenu').addEventListener("mouseleave",hideSubMenu2);
  document.getElementById('subtog2').removeEventListener("click",showSubMenu2);

  for (let i=0;i<document.getElementsByClassName('subsub').length;i++){
    document.getElementsByClassName('subsub')[i].classList.add('subadd');
  }





 for (let i=0;i<document.getElementsByClassName('has-sub').length;i++){
   if(document.getElementsByClassName('subsub')[i].childNodes.length > 0){
     document.getElementsByClassName('has-sub')[i].addEventListener("mouseover",function(){showSubSubDesktop(i)});
     document.getElementsByClassName('has-sub')[i].addEventListener("mouseleave",function(){hideSubSubDesktop(i)});
     document.getElementsByClassName('subsub')[i].addEventListener("mouseover",function(){showSubSubDesktop(i)});
     document.getElementsByClassName('subsub')[i].addEventListener("mouseleave",function(){hideSubSubDesktop(i)});
   }

  }

}
}



const showSubSub = (x) => {
if(document.getElementsByClassName('subsub')[x].style.display == 'none'){
  document.getElementsByClassName('subsub')[x].style.display = 'contents';
  document.getElementsByClassName('dicon')[x].style.transform = 'rotate(180deg)';
} else {
  document.getElementsByClassName('subsub')[x].style.display = 'none';
  document.getElementsByClassName('dicon')[x].style.transform = 'rotate(0deg)';
}
}

const showSubSubDesktop = (x) => {
  /*document.getElementsByClassName('subsub')[x].style.display = 'contents';
  if(window.matchMedia('(max-width: 1023px)').matches==true){
    document.getElementsByClassName('dicon')[x].style.transform = 'rotate(180deg)';
  } else {
  document.getElementsByClassName('dicon')[x].style.transform = 'rotate(-90deg)';
}*/
}

const hideSubSubDesktop = (x) => {
  if(window.matchMedia('(max-width: 1023px)').matches){
    document.getElementsByClassName('subsub')[x].style.display = 'none';
    document.getElementsByClassName('dicon')[x].style.transform = 'rotate(0deg)';
}
}










const loadSubMenu = () => {
  if (document.getElementsByClassName('subMenu')[0] !== null){
    if(window.matchMedia('(max-width: 1023px)').matches){
    document.getElementsByClassName('subMenu')[0].style.display = 'block';
  } else {
    document.getElementsByClassName('subMenu')[0].style.display = 'flex';
  }
    document.getElementById('down').style.transform = 'rotate(180deg)';
}
}
const loadSubMenu2 = () => {
  if (document.getElementsByClassName('subMenu')[1] !== null){

    document.getElementsByClassName('subMenu')[1].style.display = 'block';
    document.getElementById('down2').style.transform = 'rotate(180deg)';
}
}

const hideSubMenu = () => {
  if (document.getElementsByClassName('subMenu')[0] !== null){
    document.getElementsByClassName('subMenu')[0].style.display = 'none';
    document.getElementById('down').style.transform = 'rotate(0deg)';
}
}
const hideSubMenu2 = () => {
  if (document.getElementsByClassName('subMenu')[1] !== null){
    document.getElementsByClassName('subMenu')[1].style.display = 'none';
    document.getElementById('down2').style.transform = 'rotate(0deg)';
}
}
const showSubMenu = () => {
if (document.getElementsByClassName('subMenu')[0] !== null){
if(document.getElementsByClassName('subMenu')[0].style.display == 'none'){
  if(window.matchMedia('(max-width: 1023px)').matches){
  document.getElementsByClassName('subMenu')[0].style.display = 'block';
} else {
  document.getElementsByClassName('subMenu')[0].style.display = 'flex';
}
  document.getElementById('down').style.transform = 'rotate(180deg)';
} else {
  document.getElementsByClassName('subMenu')[0].style.display = 'none';
  document.getElementById('down').style.transform = 'rotate(0deg)';
}
}
}
const showSubMenu2 = () => {
if (document.getElementsByClassName('subMenu')[1] !== null){
if(document.getElementsByClassName('subMenu')[1].style.display == 'none'){
  document.getElementsByClassName('subMenu')[1].style.display = 'block';
  document.getElementById('down2').style.transform = 'rotate(180deg)';
} else {
  document.getElementsByClassName('subMenu')[1].style.display = 'none';
  document.getElementById('down2').style.transform = 'rotate(0deg)';
}
}
}


export default () => (
  <StaticQuery
    query={graphql`
      query NavQuery {
        file(relativePath: {regex: "/optimized/poland.png/"}) {
          childImageSharp {
            fixed(width: 20, height: 20) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
              srcWebp
            }
          }
        }
        offers : allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "offer-page" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
              }
            }
          }
        }
        suboffers : allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "offer-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                category
                templateKey
              }
            }
          }
        }
        portfolios : allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "portfolio-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                industry
                templateKey
                date(formatString: "DD. MM. YYYY")
            }
          }
        }
      }
      }
    `}
    render={(data, count) => <Navbar data={data} count={count} />}
  />
)
